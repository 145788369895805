import types from './types';

const saveSettings = settings => ({ type: types.saveSettings, settings });
const saveSettingsSuccess = settings => ({ type: types.saveSettingsSuccess, settings });
const saveSettingsFailure = error => ({ type: types.saveSettingsSuccess, error });
const getSettings = () => ({ type: types.getSettings });
const getSettingsSuccess = settings => ({ type: types.getSettingsSuccess, settings });
const getSettingsFailure = error => ({ type: types.getSettingsFailure, error });
const setSettings = settings => ({ type: types.setSettings, settings });

export default {
  saveSettings,
  saveSettingsSuccess,
  saveSettingsFailure,
  getSettings,
  getSettingsSuccess,
  getSettingsFailure,
  setSettings,
};
