import types from './types';

const getSearchResults = (settings) => ({ type: types.getSearchResults, settings });
const getSearchResultsSuccess = (results, moreRecords, nextCursor) => ({
  type: types.getSearchResultsSuccess,
  results,
  moreRecords,
  nextCursor,
});
const getSearchResultsFailure = (error) => ({ type: types.getSearchResultsFailure, error });
const getMoreResults = (settings, nextCursor) => ({
  type: types.getMoreResults,
  settings,
  nextCursor,
});
const getMoreResultsSuccess = (results, moreRecords, nextCursor) => ({
  type: types.getMoreResultsSuccess,
  results,
  moreRecords,
  nextCursor,
});
const getMoreResultsFailure = (error) => ({ type: types.getMoreResultsFailure, error });
const setName = (name) => ({ type: types.setSearchName, name });
const setAgeRange = (lowerBound, upperBound) => ({
  type: types.setAgeRange,
  lowerBound,
  upperBound,
});
const setGender = (gender) => ({ type: types.setSearchedGender, gender });
const setSearchRadius = (radius) => ({ type: types.setSearchRadius, radius });
const setPosition = (position) => ({ type: types.setCurrentPosition, position });
const clearSearchSettings = () => ({ type: types.clearSearchSettings });

export default {
  getSearchResults,
  getSearchResultsSuccess,
  getSearchResultsFailure,
  getMoreResults,
  getMoreResultsSuccess,
  getMoreResultsFailure,
  setName,
  setAgeRange,
  setGender,
  setSearchRadius,
  setPosition,
  clearSearchSettings,
};
