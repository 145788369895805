import { createSelector } from 'reselect';

const getSearchSettings = state => state.user.searchsettings;

const getSettings = createSelector(
  getSearchSettings,
  ({ searchedAgeFrom, searchedAgeTo, searchedDistance, searchedGender }) => ({
    age: [searchedAgeFrom, searchedAgeTo],
    radius: searchedDistance,
    gender: searchedGender,
  })
);

export default { getSearchSettings, getSettings };
