import { takeLatest, call, put } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { UpdateSettings, GetOwnSettings } from '../services/webAppEndPoint';

function* saveSettings({ settings }) {
  try {
    yield call(UpdateSettings, settings);
    yield put(actions.saveSettingsSuccess(settings));
  } catch (exception) {
    yield put(actions.saveSettingsFailure(exception));
  }
}

function* handleSaveSettings() {
  yield takeLatest(types.saveSettings, saveSettings);
}

function* getSettings() {
  try {
    const settings = yield call(GetOwnSettings);
    yield put(actions.getSettingsSuccess(settings));
  } catch (error) {
    yield put(actions.getSettingsSuccess(error));
  }
}

function* handleGetSettings() {
  yield takeLatest(types.getSettings, getSettings);
}

export default { handleSaveSettings, handleGetSettings };
