import { combineReducers } from 'redux';
import types from './types';
import { swipesTypes } from '../swipes';
import { friendsTypes } from '../friends';
import { friendShipStatus } from '../enums';
import userInformationTypes from '../userInformation/types';
import { searchsettingsTypes } from '../searchsettings';

const resultsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.getSearchResultsSuccess:
      return action.results;
    case types.getMoreResultsSuccess:
      return [...state, ...action.results];
    case friendsTypes.rejectFriendSuccess:
    case friendsTypes.removeFriendSuccess:
    case friendsTypes.sendFriendRequestSuccess:
    case friendsTypes.retreatFriendRequestSuccess:
    case friendsTypes.addFriendSuccess:
    case swipesTypes.swipeRight: {
      const { id } = action;
      return state.map((item) => (item.id === id ? resultReducer(item, action) : item));
    }
    default:
      return state;
  }
};

const pageReducer = (state = 1, action) => {
  switch (action.type) {
    case types.getSearchResultsSuccess:
      return 2;
    case types.getMoreResultsSuccess:
      return state + 1;
    default:
      return state;
  }
};

const moreRecordsReducer = (state = false, action) => {
  switch (action.type) {
    case types.getSearchResultsSuccess:
    case types.getMoreResultsSuccess:
      return action.moreRecords;
    default:
      return state;
  }
};

const resultReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case swipesTypes.swipeRight:
      return { ...state, isLiked: true };
    case friendsTypes.sendFriendRequestSuccess:
      return {
        ...state,
        friendRequestStatus: friendShipStatus.pending,
        friendshipCounter: state.friendshipCounter ? state.friendshipCounter + 1 : 1,
      };
    case friendsTypes.removeFriendSuccess:
    case friendsTypes.retreatFriendRequestSuccess:
      return { ...state, friendRequestStatus: friendShipStatus.noRequest };
    case friendsTypes.addFriendSuccess:
      return { ...state, friendRequestStatus: friendShipStatus.accepted };
    case friendsTypes.rejectFriendSuccess:
      return { ...state, friendRequestStatus: friendShipStatus.denied };
    default:
      return state;
  }
};

const loadingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.getMoreResults:
      return true;
    case types.getMoreResultsSuccess:
    case types.getMoreResultsFailure:
      return false;
    default:
      return state;
  }
};

const initLoadingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.getSearchResults:
      return true;
    case types.getSearchResultsFailure:
    case types.getSearchResultsSuccess:
      return false;
    default:
      return state;
  }
};

const settingsReducer = (
  state = {
    searchedDistance: 150,
    searchedAgeFrom: 18,
    searchedAgeTo: 45,
    searchedGender: 2,
    name: '',
  },
  action
) => {
  const { type } = action;
  switch (type) {
    case types.setSearchName: {
      const { name } = action;
      return { ...state, name };
    }
    case searchsettingsTypes.saveSettingsSuccess:
    case searchsettingsTypes.getSettingsSuccess:
    case searchsettingsTypes.setSettings: {
      return { ...state, ...action.settings };
    }
    case userInformationTypes.updateUserInformation: {
      const { information } = action;
      if (information.settings !== undefined) return { ...state, ...information.settings };
      return state;
    }
    default:
      return state;
  }
};

export default combineReducers({
  results: resultsReducer,
  isLoading: loadingReducer,
  initLoading: initLoadingReducer,
  settings: settingsReducer,
  nextPage: pageReducer,
  moreRecords: moreRecordsReducer,
});
