import types from './types';
import { userInformationTypes } from '../userInformation';
import searchTypes from '../search/types';

const reducer = (
  state = {
    searchedDistance: 150,
    searchedAgeFrom: 18,
    searchedAgeTo: 45,
    searchedGender: 2,
    name: ''
  },
  { type, ...actionParameter },
) => {
  switch (type) {
    case types.saveSettingsSuccess:
    case types.getSettingsSuccess:
    case types.setSettings:
      return actionParameter.settings;
    case searchTypes.setSearchName:{
      const { name } = actionParameter;
      return { ...state, name };
    }
    case userInformationTypes.updateUserInformation: {
      const { information } = actionParameter;
      if (information.settings !== undefined)
        return information.settings;
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
