import { createSelector, createStructuredSelector } from 'reselect';

const getSearchPart = state => state.search;
const getSearchSettings = state => getSearchPart(state).settings;
const getResults = state => getSearchPart(state).results;
const getMoreRecords = state => getSearchPart(state).moreRecords;
const getNextPage = state => getSearchPart(state).nextPage;
const getIsLoading = state => getSearchPart(state).isLoading;
const getIsInitLoading = state => getSearchPart(state).initLoading;
const getSearchName = state => getSearchSettings(state).name;
const getSearchAgeTo = state => getSearchSettings(state).searchedAgeTo;
const getSearchAgeFrom = state => getSearchSettings(state).searchedAgeFrom;
const getSearchGender = state => getSearchSettings(state).searchedGender;
const getSearchRadius = state => getSearchSettings(state).searchedDistance;

const getAgeRange = createSelector(getSearchAgeTo, getSearchAgeFrom, (to, from) => [from, to]);

const getSettings = createStructuredSelector({
  name: getSearchName,
  age: getAgeRange,
  gender: getSearchGender,
  radius: getSearchRadius
});

export default {
  getSearchSettings,
  getResults,
  getMoreRecords,
  getNextPage,
  getIsLoading,
  getIsInitLoading,
  getSettings
};
