const saveSettings = 'app/user/settings/SAVESETTINGS';
const saveSettingsSuccess = 'app/user/settings/SAVESETTINGSSUCCESS';
const saveSettingsFailure = 'app/user/settings/SAVESETTINGSFAILURE';
const getSettings = 'app/user/settings/GETSETTINGS';
const getSettingsSuccess = 'app/user/settings/GETSETTINGSSUCCESS';
const getSettingsFailure = 'app/user/settings/GETSETTINGSFAILURE';
const setSettings = 'app/user/settings/SETSETTINGS';
export default {
  saveSettings,
  saveSettingsSuccess,
  saveSettingsFailure,
  getSettings,
  getSettingsSuccess,
  getSettingsFailure,
  setSettings,
};
