const getSearchResults = 'app/search/GET';
const getSearchResultsSuccess = 'app/search/GETSUCCESS';
const getSearchResultsFailure = 'app/search/GETFAILURE';
const getMoreResults = 'app/search/GETMORE';
const getMoreResultsSuccess = 'app/search/GETMORESUCCESS';
const getMoreResultsFailure = 'app/search/GETMOREFAILURE';
const setSearchName = 'app/search/settings/SETNAME';
const setAgeRange = 'app/search/settings/SETAGE';
const setSearchedGender = 'app/search/settings/SETGENDER';
const setSearchRadius = 'app/search/settings/SEARCHRADIUS';
const setCurrentPosition = 'app/search/settings/SETPOSITION';
const clearSearchSettings = 'app/search/settings/CLEAR';

export default {
  getSearchResults,
  getSearchResultsSuccess,
  getSearchResultsFailure,
  getMoreResults,
  getMoreResultsSuccess,
  getMoreResultsFailure,
  setSearchName,
  setAgeRange,
  setSearchedGender,
  setSearchRadius,
  setCurrentPosition,
  clearSearchSettings,
};
